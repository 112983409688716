$main-content-plugin-margin: 20px;

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html.cms-toolbar-expanded {
}

html.app, html.app body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  @include font-serif();

  font-size: 14px;
  background-color: #000;
}

/**
 * Setting this property on the iframe will cause the RTF editor to break. This property is set dynamically via skrollr.
 * The RTF editor is in an iframe so resetting it back on iframes where only when the CMS is ready is a good fix.
 */
.cms-ready iframe {
  transform: rotate3d(0, 0, 0, 45deg);
}

h1, h2, h3, h4, h5 {
  @include font-sans-serif();
}

.last-section {
  display: inline;
}

.main-content {
  position: relative;
  overflow: hidden;
  padding: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .heading {
    margin-bottom: $main-content-plugin-margin;

    //		overflow: auto;
    //		width: 100%;

    @include clearfix();

    h1 {
      padding: 10px 0 22px;
      font-size: 2.5em;
      margin-bottom: 0;

      .app-miss-lilys & {
        line-height: 1.4em;
      }

      &.img-header {
        height: 88px;

        img {
          display: block;
          max-width: 100%;
        }
      }
    }

    &.with-subhead {
      h1 {
        padding: 2px 0 8px;
      }

      h2 {
        padding-bottom: 8px;
        font-size: 1em;
      }
    }

    a.btn {
      float: right;
      text-decoration: none;
      display: block;
      padding: 10px;
      margin: 12px 0;
      border-width: 1px;
      border-style: solid;
      @include font-sans-serif();
    }

    .icon-mouse {
      display: none;
      text-align: center;
      width: 20px;
      float: right;
      margin-right: 20px;

      span {
        @include font-sans-serif(true);

        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
        display: block;
        margin: 0 -40px;
      }

      .app-miss-lilys & {
        margin-top: 13px;
      }
    }

    &.with-subhead {
      a.btn {
        margin-top: 10px;
      }
    }
  }

  .video-js {
    padding-left: 100%;
    padding-top: 56%;
    width: 0 !important;
    height: 0 !important;
    margin: $main-content-plugin-margin 0;
  }

  div.plugin-wrapper:not(.plugin-text_plugin) {
    .plugin_picture {
      img {
        display: block;
        width: 85%;
        margin: $main-content-plugin-margin auto;
        border-width: 15px;
        border-style: solid;
      }
    }
  }

  .normal-picture {
    .plugin_picture {
      img {
        //display: block;
        //width: auto;
        //border-width: 0;
      }
    }
  }

  blockquote {
    margin: $main-content-plugin-margin 0;
    padding: 0 70px;

    p {
      font-size: 1.7em;
    }

    .icon {
      padding: 0 13px 4px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
  }

  p {
    line-height: 1.5em;
    margin: 20px 0;
    font-size: 0.9em;
  }

  .plugin-text_plugin {
    a {
      text-decoration: none;
      border-bottom-style: dotted;
      border-bottom-width: 1px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 1em;
    }

    h1 { font-size: 2.0em; }
    h2 { font-size: 1.75em; }
    h3 { font-size: 1.4em; }
    h4 { font-size: 1.2em; }
    h5 { font-size: 1.0em; }
    h6 { font-size: 0.8em; }

    ul, ol {
      list-style: disc;
      margin-top: 2em;
      margin-bottom: 2em;
      margin-left: 3em;

      li {
        margin: 1em;
      }
    }

    ol {
      list-style: decimal;
    }

    table {
      margin: $main-content-plugin-margin -8px;
      width: 100%;

      th, td {
        padding: 8px;
      }

      thead {
        border-bottom: 1px solid rgba(255, 255, 255, 0.13);

        th {
          text-align: left;
          font-weight: bold;
        }
      }

      tbody {
        tr:nth-child(odd) {
          background-color: rgba(255, 255, 255, 0.1);
        }

        tr:hover {
          td {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .plugin_picture {
      //img {
      //  display: inline;
      //  width: auto;
      //  margin: auto;
      //  border: 0;
      //}
    }
  }

  a.btn-large {
    text-transform: uppercase;
    padding: 1.5em 3em;
    @include font-sans-serif();
    transition: background-color $header-fade-easing $header-fade-speed;
    border-bottom: 0;
    border-radius: 7px;
    text-align: center;
    font-size: $header-book-now-font-size;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    color: $header-book-now-color;
    background-color: $header-book-now-background-color;

    &:hover {
      background-color: $header-book-now-background-color-hover;
    }
  }

  hr {
    border: 0;
    border-top-width: 1px;
    border-top-style: solid;
    margin: 2.5em 0;
  }

  p.large-text {
    font-size: 1.1em;
  }
}

.divider {
  clear: both;
}

ul.site-navigation {
  margin-top: 1px;
  overflow: hidden;
  list-style: none;

  li {
    display: block;
    float: left;
    width: 50%;

    &:first-child {
      padding-right: 1px;
    }

    &.next {
      float: right;
      text-align: right;
    }

    a {
      @include font-sans-serif();
      display: block;
      text-decoration: none;
      overflow: hidden;
      padding: 30px 20px;

      .app-rockhouse-hotel &.toggle-menu {
        font-size: 26px;
      }
    }

    &:first-child i {
      margin-left: 0;
      margin-right: 14px;
    }

    i {
      font-size: 0.7em;
      margin-left: 14px;
    }


  }
}

.visible-desktop {
  display: none;
}

.hidden-desktop {
  display: none;
}

.visible-mobile {
  display: none;
}

.hidden-mobile {
  display: none;
}

/** mobile */
@media(max-width: $mobile-breakpoint) {
  html.app body {
    padding-top: $header-height - 1;

    .hidden-desktop, .visible-mobile {
      display: block;
    }
  }
}

@media(min-width: $mobile-breakpoint + 1) {
  body {
    .hidden-mobile, .visible-desktop {
      display: block;
    }
  }

  section {
    .main-content {
      .heading {
        .icon-mouse {
          display: block;
        }
      }

      float: right;
      width: $main-content-width;
      padding: 25px 50px 30px;
      margin-top: 0;
      margin-right: $main-content-margin;
      margin-left: $main-content-margin;
      margin-bottom: $section-margin-bottom;
    }

    .last-section & .main-content {
      margin-bottom: $section-margin-bottom-last;
    }
  }

  ul.site-navigation {
    float: right;
    width: $main-content-width;
    margin: 0 $main-content-margin;
    margin-bottom: 100px;

    //		padding-top: 21px;

    li.prev {
      padding-right: 1px;
    }
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: $main-nav-section-divider-color;
  border: 1px solid transparent;
  margin: 1px;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}



@media( max-width: $mobile-breakpoint ) {
  .main-content .heading h1.img-header {
    height: auto;
  }
}
