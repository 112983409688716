$fa-font-path: "../fonts/font-awesome";

@import "reset";
@include global-reset();

@import "variables";

@import "pickadate/theme";

@import "select2";
@import "font-awesome/font-awesome";
@import "hint";

@import "bootstrap/mixins";
@import "structure";
@import "themes";
@import "menu-and-logo";
@import "header";
@import "nav";
@import "supporters";

@import "preloader";
@import "parallax";
@import "reservations";
@import "instagram";
@import "opentable";

p.plugin_file {
  img {
    display: none;
  }

  .filesize {
    display: none;
  }
}

.plugin-open_table_cms_plugin {
}

/* way the hell off screen */
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.iubenda_legal_document {
  h2 {
    font-size: 24px;
    margin: 20px 0 10px;
  }

  h3 {
    margin: 10px 0;
    font-size: 20px;
  }

  h4 {
    margin: 10px 0;
    font-size: 16px;
  }
}

//.split-columns {
//  padding: 0 30px;
//}

