.instagram-section {
	margin: $main-content-plugin-margin 0;

	ul {
		list-style: none;
		overflow: hidden;
		margin: 0 3em;

		li {
			position: relative;
			float: left;

			margin: 0;

			padding-left: 33%;
			padding-top: 33%;

			height: 0;
			width: 0;

			a {
				display: block;
				position: absolute;

				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.plugin-text_plugin {
		p {
			a.logo-instagram {
				border-bottom: 0;

				img {
					padding-left: 2px;
					height: 20px;
					vertical-align: middle;
				}
			}
		}
	}
}
