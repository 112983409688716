.skrollr-desktop body {
  height: 100% !important;
}

#skrollr-body {
  height: 100%;
  overflow: visible;
  position: relative;
  z-index: 500;
}

.parallax-image-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.parallax-image-wrapper-100 {
  height: 100%;
}

.parallax-image-first > .parallax-image > div {
  @extend .animate-image;
}

.parallax-image {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  div {
    display: none;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@mixin gradient($direction) {
  @include stretch();
  z-index: 600;
  pointer-events: none;
  background-image: radial-gradient(
      $direction,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 150px
  );
}

.gradient-1 {
  @include gradient(left top);
}

.gradient-2 {
  @include gradient(right top);
}

.parallax-image-100 {
  height: 100%;
}

.parallax-image.skrollable-between {
  display: block;
}

.no-skrollr .parallax-image-wrapper {
  display: none !important;
}

section.section-spacer {
  padding-bottom: 0;

  div {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 56.25%;
    display: none;
  }
}

#section-pager {
  position: fixed;
  display: none;
  z-index: 510;
  right: 0;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  list-style: none;
  margin: 0;
  padding: 10px;

  li {
    a {
      display: block;
      padding: 10px;
      color: #999;
      font-size: 8px;
      text-align: center;
      text-decoration: none;

      .fa-circle-thin {
        display: none !important;
      }
    }

    &.active {
      a {
        .fa-circle-thin {
          display: block !important;
          font-size: 12px;
        }

        .fa-circle {
          display: none;
        }
      }
    }
  }
}

@media(max-width: $mobile-breakpoint) {
  .parallax-image-wrapper {
    display: none;
  }

  section.section-spacer {
    div {
      display: block;
    }
  }
}

@media(min-width: $mobile-breakpoint + 1) {
  section.section-spacer {
    margin-bottom: - $main-content-offset;

    .app-miss-lilys & {
      margin-bottom: - $main-content-offset-miss-lilys;
    }

    width: 100%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;

    .cms-toolbar-expanded & {
        margin-bottom: - $main-content-offset-cms;
    }

    .app-miss-lilys.cms-toolbar-expanded & {
      margin-bottom: - $main-content-offset-cms-miss-lilys;
    }
  }
}

@media(max-width: $mobile-breakpoint) {
  .parallax-image {
    .mobile {
      display: block;
    }
  }
}

@media(min-width: $mobile-breakpoint + 1) {
  .parallax-image {
    .normal {
      display: block;
    }
  }
}

@media(min-width: $mobile-breakpoint + 1) and (min-device-pixel-ratio: 2), (min-width: $large-breakpoint) {
  .parallax-image {
    .normal { display: none; }
    .retina {
      display: block;
    }
  }
}

@-webkit-keyframes move {
  from {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -moz-transform: scale(1)
  }
  to {
    transform: scale(1.15);
    -ms-transform: scale(1.15);
    -webkit-transform: scale(1.15);
    -o-transform: scale(1.15);
    -moz-transform: scale(1.15)
  }
}

.animate-image {
  @include animation-duration(30s);
  @include animation-fill-mode(both);
  @include animation-iteration-count(infinite);
  @include animation-name(move);
  @include animation-direction(alternate);
  @include transform-origin(bottom center);
}

@keyframes move {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.15);
  }
}
