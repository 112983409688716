
/* ==========================================================================
   $DEFAULT-TIME-PICKER
   ========================================================================== */

/**
 * The frame the bounds the time picker.
 */
.picker--time .picker__frame {

    // Adjust the min & max widths.
    min-width: $time-min-width;
    max-width: $time-max-width;
}


/**
 * The picker box.
 */
.picker--time .picker__box {

    // Keep the font-size small to show more in view.
    font-size: 1em;

    // Add a slight background color.
    background: $bg-grey-light;

    // Remove the side paddings.
    padding: 0;

    // For 'medium' screens, move it away from the bottom edge of the screen.
    @media ( min-height: $breakpoint-medium ) {
        margin-bottom: 5em;
    }
}



