$daySize: 36px;
$calendar-font-size: 1.2rem;
$calendar-font-color: #fff;
$calendar-background-color: #333;

#OT_searchWrapperAll {
  position: relative;
  text-align: center;

  .OT_feedTitle {
  }

  dl {
    padding: 0;
    margin: 0
  }

  form {
    margin: 0;
    padding: 0
  }

  input.hidden {
    display: none
  }

  .feedFormField, .feedFormField option {
  }

  #startDate, .OT_feedFormfieldCalendar {
    width: 70px;
  }

  #dateDisplay {
    display: none;
  }

  .imgCal {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 20px;
    display: none;
    z-index: 100
  }

  #imageCal {
    margin: auto;
    font-size: $calendar-font-size;
    table {
      background-color: $calendar-background-color;
      width: 250px;
      font-weight: bold;
    }

    td {
      background-color: $calendar-background-color;
      text-align: center;

      table {
        td {
          background-color: $calendar-background-color;
        }
      }

      table.monthRow {
        td {
          padding: 5px;
          color: $calendar-font-color;
          background-color: $calendar-background-color;
        }

        a.leftArrowLink,
        a.rightArrowLink {
          display: block;
          color: $calendar-font-color;
          text-decoration: none;

          img {
            display: none;
          }

          &:after {
            display: block;
            content: $fa-var-angle-left;
            font-family: 'FontAwesome';
          }
        }

        a.rightArrowLink:after {
          content: $fa-var-angle-right;
        }
      }

      &.weekdays {
        color: #fff;
        font-size: 0.8em;
        //background-color: $calendar-background-color;
        padding: 5px;
      }

      table:last-child {
        tr:first-child {
          display: none;
        }
        tr:last-child {

          td:first-child {
            display: none;
            padding-right: 1px;
          }

          td {
            padding-top: 1px;
            background-color: $calendar-background-color;
          }

          a {
            display: block;
            background: $calendar-background-color;
            color: #fff;
            text-decoration: none;
            padding: 5px;

            &:hover {
              background: #555;
            }

            &:after {
              font-size: 0.7em;
              display: block;
              content: "Close";
            }

            img {
              display: none;
            }
          }
        }
      }
    }
  }

  .clickableDays {
    text-decoration: none;
    background-color: transparent !important;

    a {
      background-color: transparent !important;
      color: $calendar-font-color;
      display: block;
      padding: 5px 0;

      &:hover {
        background-color: #797979 !important;
      }

    }
  }

  .nonclickableDays {
    color: #ccc;
    padding: 5px 0;
  }

  .months {
    //color: #fff;
    text-decoration: none
  }

}

$open-table-label-padding-top: 10px;
$open-table-label-padding-bottom: 5px;

#OT_restaurantLbl {
  padding-top: $open-table-label-padding-top;
  padding-bottom: $open-table-label-padding-bottom;
}

#OT_restaurant {
}

#OT_partySizeLbl {
  padding-top: $open-table-label-padding-top;
  padding-bottom: $open-table-label-padding-bottom;
}

#OT_partySize {
}

#OT_dateLbl {
  padding-top: $open-table-label-padding-top;
  padding-bottom: $open-table-label-padding-bottom;
}

#OT_date {
  position: relative;
}

#OT_timeLbl {
  padding-top: $open-table-label-padding-top;
  padding-bottom: $open-table-label-padding-bottom;
}

#OT_submitWrap {
  padding-top: $open-table-label-padding-top * 2;
}

.partOfCal img.partOfCal.spacer {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0
}