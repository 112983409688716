$themes: (
  // miss lilys
  miss-lilys-cyan: (
    background-color: #1ea390,
    darker-color: #177d6e,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #fff,
    previous-font-color: #ecf0f1
  ),

  miss-lilys-dark-blue: (
    background-color: #143f69,
    darker-color: #0f3050,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #fff,
    previous-font-color: #ecf0f1
  ),

  miss-lilys-green: (
    background-color: #139046,
    darker-color: #13743a,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #fff,
    previous-font-color: #ecf0f1
  ),

  miss-lilys-blue: (
    background-color: #38abdb,
    darker-color: #2e8ab3,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #fff,
    previous-font-color: #ecf0f1
  ),

  miss-lilys-yellow: (
    background-color: #fbd309,
    darker-color: #cdb105,
    icon-color: #898b8c,
    border-color: #898b8c,
    font-color: #000,
    previous-font-color: #898b8c
  ),

  miss-lilys-red: (
    background-color: #ed2228,
    darker-color: #b51a1e,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #fff,
    previous-font-color: #ecf0f1
  ),

  miss-lilys-brown: (
    background-color: #a64325,
    darker-color: #7f331c,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #fff,
    previous-font-color: #ecf0f1
  ),


  // rockhouse
  eat: (
    background-color: #2c3e50,
    darker-color: #2e3b49,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #ecf0f1,
    previous-font-color: #ecf0f1
  ),
  escape: (
    background-color: #ecf0f1,
    darker-color: #c1c5c6,
    icon-color: #333,
    border-color: #97a1a9,
    font-color: #333,
    previous-font-color: #333
  ),

  sleep: (
    background-color: #16a085,
    darker-color: #105543,
    icon-color: #ecf0f1,
    border-color: #97a1a9,
    font-color: #ecf0f1,
    previous-font-color: #ecf0f1
  ),
  play: (
    background-color: #976da9,
    darker-color: #51395c,
    icon-color: #ecf0f1,
    border-color: #97a1a9,
    font-color: #ecf0f1,
    previous-font-color: #ecf0f1
  ),
  spa: (
    background-color: #f26d7d,
    darker-color: #934549,
    icon-color: #ecf0f1,
    border-color: #ecf0f1,
    font-color: #ecf0f1
  ),
  rates: (
    background-color: #c0392b,
    darker-color: #812a21,
    icon-color: #ecf0f1,
    border-color: #97a1a9,
    font-color: #ecf0f1,
    previous-font-color: #ecf0f1
  ),
  press: (
    background-color: #9b59b8,
    darker-color: #6a4080,
    icon-color: #ecf0f1,
    border-color: #97a1a9,
    font-color: #ecf0f1,
    previous-font-color: #ecf0f1
  ),
  weddings: (
    background-color: #7f8c8d,
    darker-color: #727f80,
    icon-color: #ecf0f1,
    border-color: #97a1a9,
    font-color: #ecf0f1,
    previous-font-color: #ecf0f1
  ),
  information: (
    background-color: #27ae60,
    darker-color: #208040,
    icon-color: #ecf0f1,
    border-color: #97a1a9,
    font-color: #ecf0f1,
    previous-font-color: #ecf0f1
  ),

  graph-paper: (
    background-image: url( ../img/bg-grid.png ),
    background-color: #c1c5c6,
    darker-color: #c1c5c6,
    icon-color: #333,
    border-color: #97a1a9,
    font-color: #333,
    previous-font-color: #333
  )
);

@mixin theme( $theme ) {
  $font-color: map-get($theme, "font-color");
  $darker-color: map-get($theme, "darker-color");
  $background-color: map-get($theme, "background-color");
  $background-image: map-get($theme, "background-image");
  $border-color: map-get($theme, "border-color");
  $icon-color: map-get($theme, "icon-color");
  $previous-background-color: map-get($theme, "previous-background-color");
  $previous-font-color: map-get($theme, "previous-font-color");

  .main-content {
    @if( $background-image ) {
      background-image: $background-image;
    } @else {
      background-color: rgba($background-color, 0.9);
    }
    
    color: $font-color;

    .plugin-text_plugin {
      a {
        color: $font-color;
        border-bottom-color: $font-color;
      }
    }

    .heading {
      color: $font-color;
      border-bottom: 1px solid $border-color;

      .icon-mouse {
        color: $icon-color;

        svg path {
          fill: $icon-color;
        }
      }

      a.btn {
        border-color: $border-color;
        color: $font-color;
      }
    }

    .plugin_picture {
      img {
        border-color: $darker-color;
      }
    }

    blockquote {
      .icon {
        border-bottom-color: $border-color;
      }
    }

    &:after {
      border-top-color: rgba($background-color, 0.9);
    }

    hr {
      border-top-color: $border-color;
      margin: 1em;
    }
  }

  ul.site-navigation {
    border-top-color: $border-color;

    li {
      a {
        @if( $background-image ) {
          background-image: $background-image;
        } @else {
          background-color: $background-color;
        }
        color: $font-color;
      }
    }

    li.prev {
      a {
        background-color: $previous-background-color;
        color: $previous-font-color;
      }
    }
  }

  @media( max-width: $mobile-breakpoint ) {
    .main-content {
      background-color: opacify($background-color, 1);
    }
  }
}


@each $theme-name, $theme in $themes {
  .theme-#{$theme-name} {
    @include theme( $theme )
  }
}

