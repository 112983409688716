@mixin font-serif($force-original: false) {
  font-family: Lora, sans-serif;

  @if not $force_original {
    .app-miss-lilys & {
      font-family: 'Times New Roman', serif;
      font-size: 16px;
    }
  }
}

@mixin font-sans-serif($force-original: false) {
  font-family: Abel, sans-serif;

  @if not $force_original {
    .app-miss-lilys & {
      font-family: 'Cooper Black W01', sans-serif;
    }
  }
}

@mixin clearfix() {
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

$select2-images-url: "../img/select2";
$select2-spinner-url: $select2-images-url + '/select2-spinner.gif';
$select2-sprite-sheet-url: $select2-images-url + '/select2.png';
$select2-sprite-sheet-retina-url: $select2-images-url + '/select2x2.png';

$twitter-color: #28aae1;
$facebook-color: #3a5897;
$instragram-color: #564c3e;
$tumblr-color: #35465c;
$flickr-color: #0063dc;

$cms-toolbar-height: 46px;
$header-alt-top: $cms-toolbar-height;
$menu-alt-top: $cms-toolbar-height;

$menu-button-normal: #fff;
$menu-button-normal-alt: #555;
$menu-button-hover: #555;
$menu-button-active: #fff;

$menu-open-speed: 0.25s;
$menu-open-easing: linear;

$menu-background-color: #333;
$menu-width: 260px;

$header-fade-speed: $menu-open-speed;

$header-fade-easing: linear;

$header-book-now-color: #fff;
$header-book-now-background-color: #FF8F00;
$header-book-now-background-color-hover: #FFAA3D;
$header-book-now-padding: 12px;
$header-book-now-font-size: 16px;

$header-social-network-icons: #fff;
$header-social-network-icons-box-size: 40px;

$header-height: 59px;

$main-content-offset: 93px;
$main-content-offset-cms: $main-content-offset + $cms-toolbar-height;

$main-content-offset-miss-lilys: 115px;
$main-content-offset-cms-miss-lilys: $main-content-offset-miss-lilys + $cms-toolbar-height;

$main-content-width: 600px;
$main-content-margin: 50px;

$section-margin-bottom: 50px;
$section-margin-bottom-last: 20px;

$total-width-of-page: $main-content-width + ( $main-content-margin * 2 );

$field-required-color: #f39c12;
$field-background-color: #333d41;
$field-placeholder-color: #7f8c8d;
$field-color: #fff;
$field-label-color: #bdc3c7;
$field-input-outline: $field-required-color;
$field-input-selection: #804000;
$field-input-error-border: $field-required-color;

$reservations-title-color: #ecf0f1;
$reservations-title-font-size: 2em;
$reservations-content-separator: #3f4142;

$mobile-breakpoint: 700px;
$large-breakpoint: 1600px;

$field-input-width: 100%;

$main-nav-color: #999;
$main-nav-color-active: #fff;
$main-nav-color-hover: $main-nav-color-active;
$main-nav-section-divider-color: #777;

@mixin stretch($top: 0, $left: 0, $right: 0, $bottom: 0, $position: fixed, $cms-top-fix: true) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;

  @if $cms-top-fix {
    .cms-toolbar-expanded & {
      top: $top + $cms-toolbar-height;
    }
  }
}
