// ==========================================================================
// $VARIABLES
// ==========================================================================

//
// Base colors
//
$blue: #0089ec;
$blue-hover: #b1dcfb;
$black: #000;
$white: #fff;

//
// Backgrounds
//
$bg-white: $field-background-color;
$bg-grey-light: #f2f2f2;

//
// Borders
//
$border-grey: #777;
$border-grey-light: #ddd;
$border-select: darken($border-grey-light, 15%);

//
// Buttons
//
$clear-red: #e20;

//
// Picker base
//

// Make sure nothing is above the picker.
$picker-z-index: 10000;

// Animation speeds.
$speed-animate-in: .15s;

// Focused input border color.
$input-active-border: $blue;

// Typography.
$base-font-size: 16px;
$base-line-height: 1.2;

// Corners.
$picker-border-radius: 5px;

// Drop shadows.
$picker-box-shadow: 0 12px 36px 16px rgba(0, 0, 0, .24);
$picker-box-shadow-light: 0 6px 18px 1px rgba(0, 0, 0, .12);

// Height breakpoints.
$breakpoint-tiny: 26.5em;
// 424px $ 16px
$breakpoint-small: 33.875em;
// 542px $ 16px
$breakpoint-medium: 40.125em;
// 642px $ 16px
$breakpoint-large: 46.75em;
// 748px $ 16px

// Width breakpoints.
$breakpoint-width-tiny: 24.5em;
// 392px $ 16px

//
// Date picker options
//

// The year and weekday labels.
$year-weekday-label: $field-placeholder-color;
$day-label: $field-color;

// "Today" tag indicators.
$blue-tag: #f00;
$disabled-tag: #aaa;

// Disabled things.. such as days, month nav, etc.
$disabled-things-bg: #f5f5f5;
$disabled-things-text: $field-placeholder-color;
$disabled-highlighted-things-bg: #bbb;

//
// Theme configurations
//

// The "default" min & max widths.
$picker-min-width: 256px;
$picker-max-width: 666px;

// The time picker min & max widths.
$time-min-width: $picker-min-width;
$time-max-width: 320px;

// The "classic" theme settings.
$classic-max-width: $picker-max-width - 200px;
$classic-min-width: $picker-min-width - 80px;
$classic-max-height: 25em;
$classic-box-shadow: 0 6px 18px 1px rgba(0, 0, 0, .12);

// ==========================================================================
// $MIXINS
// ==========================================================================

//
// Common picker item states
//

// Highlighted.
@mixin picker-item-highlighted() {
	border-color: $field-input-outline;
}

// Hovered.
@mixin picker-item-hovered() {
	cursor: pointer;
//	color: $black;
//	background: $blue-hover;
	border-color: lighten($field-input-outline, 0.5);
}

// Selected.
@mixin picker-item-selected() {
//	border-color: $field-input-outline;
	background: $field-input-selection;
	color: $white;
}

// Disabled.
@mixin picker-item-disabled() {
	background: $disabled-things-bg;
	border-color: $disabled-things-bg;
	color: $disabled-things-text;
	cursor: default;
}


