$container-width: 800px;
$container-left-padding: 50px;

.reservations {
  @include stretch();
  display: none;
  z-index: 2000;

  .background {
    background-color: #000;

    .app-miss-lilys & {
      background-size: cover;
      background-image: url(../img/misslilys/bg-book-now.jpg);
    }
  }

  .main-content {
    padding: 65px 30px 30px;

    p {
      color: #fff;

      a {
        color: $field-required-color;
        text-decoration: none;
      }
    }
  }
}

.show-reservations {
  overflow: hidden;

  .cms-toolbar-expanded & {
    top: $cms-toolbar-height;
  }

  header {
    background-color: #000;

    ul {
      display: none;
    }

    .app-miss-lilys & {
      background-color: transparent;
    }
  }

  .reservations {
    display: block;

    .background {
      @include stretch();
      z-index: 50;
    }

    .main-content {
      @include stretch();
      z-index: 1000;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

.content,
.form {
  h1 {
    color: $reservations-title-color;
    font-size: $reservations-title-font-size;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $reservations-content-separator;

    span {
      float: right;
      padding-top: 10px;
      font-size: 16px;
      color: #95a5a6;

      .required-field {
        float: none;
      }
    }
  }

  .required-field {
    color: $field-required-color;
  }

  .ctrlHolder {
    position: relative;
    //		padding-right: 15px;
    margin: 10px 0;

    label {
      @include font-sans-serif();
      color: $field-label-color;
    }

    .field-required-wrapper {
      padding: 0 15px;
      position: relative;
    }

    .required-field {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -20px;
    }

    &.error {
      input {
        border: 1px solid $field-input-error-border;
      }

      a.select2-choice {
        border: 1px solid $field-input-error-border;
      }
    }

    input, textarea {
      @include font-sans-serif();
      font-size: 14px;
      padding: 10px;
      width: $field-input-width;
      background-color: $field-background-color;
      color: $field-color;
      border: 1px solid $field-background-color;

      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::-webkit-input-placeholder {
        color: #7f8c8d
      }

      &:focus {
        outline: none;
        border: 1px solid $field-input-outline;
      }
    }
  }

  .reservation-total-nights-count {
    @include font-sans-serif();
    text-align: center;
    padding-bottom: 13px;
    font-size: 1.3em;

    strong {
      color: $field-required-color;
      font-size: 2em;
    }
  }

  .room-details {
    clear: both;
    position: relative;
    margin-bottom: 15px;
    margin-top: 30px;
    padding-bottom: 25px;
    padding-top: 30px;
    border-bottom: 1px solid $reservations-content-separator;
    border-top: 1px solid $reservations-content-separator;
    overflow: hidden;
  }

  .customer-phone-email {
    border-bottom: 1px solid $reservations-content-separator;
    padding-bottom: 5px;
  }

  .date-field {
    padding: 0 15px;

    label {
      float: left;
      padding-top: 14px;
      padding-bottom: 8px;
    }

    .ctrlHolder {
      position: relative;

      &:after {
        display: block;
        position: absolute;
        right: 25px;
        top: 50%;
        margin-top: 9px;
        font-family: "FontAwesome";
        content: "\f133";
        font-size: 16px;
        color: $field-placeholder-color;
        pointer-events: none;
      }
    }
  }

  .customer-name, .customer-zip-country, .customer-phone-email {
    margin-bottom: 15px;
  }

  .room-details, .customer-name, .customer-address, .customer-city-state, .customer-zip-country, .customer-phone-email, .reservation-comments {
    padding-left: 15px;
    padding-right: 15px;
  }

  .buttonHolder {
    padding-right: 30px;
  }

  .buttonHolder {
    overflow: hidden;

    input {
      @include font-sans-serif();
      @include transition(background-color $header-fade-easing $header-fade-speed);
      border: 0;
      font-size: $header-book-now-font-size;
      float: right;
      background-color: $header-book-now-background-color;
      color: $header-book-now-color;
      padding: $header-book-now-padding;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        background-color: $header-book-now-background-color-hover;
      }
    }
  }

  p.error-message {
    display: none;
    color: $field-input-error-border;
    margin-bottom: 1em;
  }

  form.error {
    p.error-message {
      display: block;
    }
  }

  .thank-you {
    display: none;
  }

  &.show-thank-you {
    form {
      display: none;
    }

    .thank-you {
      display: block;
    }
  }
}

.restaurant-logos {
  list-style: none;
  text-align: center;
  padding-bottom: 40px;

  > li {
    max-width: ( $container-width - $container-left-padding - 20 ) / 2;
    width: 100%;
    display: inline-block;
    position: relative;

    &:first-child {
      padding-right: 20px;
    }

    > a {
      @include font-sans-serif();
      text-align: center;
      display: block;
      padding: 0 10px 10px;
      font-size: 1.4em;
      text-decoration: none;
      color: $reservations-title-color;

      > img {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    color: #000;
    text-decoration: none;

    &:last-child {
      border-right: 0;
    }
  }
}

@media(min-width: $mobile-breakpoint + 1) {
  #div_id_room_type {
    float: right;
    width: 100%;

    .select2-container {
      margin-right: 170px;
      width: auto;
      display: block;
    }
  }

  #div_id_number_of_rooms {
    overflow: hidden;
    float: right;
    width: 170px;
    margin-right: -100%;

    label {
      float: left;
      width: 100px;
      padding-top: 12px;
    }

    input {
      float: left;
      width: 50px;
    }
  }

  #div_id_departure_date {
    float: right;

    label {
      margin-left: 20px;
    }
  }

  .form {
    .customer-name, .customer-city-state, .customer-zip-country, .customer-phone-email {
      overflow: hidden;
      margin: 10px 0;

      .ctrlHolder {
        margin: 0;
        width: 50%;
        float: left;
      }
    }

    .customer-name, .customer-zip-country, .customer-phone-email {
      margin-bottom: 35px;
    }

    .customer-phone-email {
      padding-bottom: 35px;
    }

    .stay-duration {
      overflow: hidden;
      max-width: 350px;
      margin: auto;

      .ctrlHolder {
        float: left;
        width: 50%;
      }
    }

    .date-field {
      padding: 0;

      label {
        padding-bottom: 0;
      }

      .ctrlHolder {
        input {
          float: right;
          width: 110px;
        }

        &:after {
          margin-top: -8px;
        }
      }
    }
  }

  .reservations {
    .main-content {
      .wrapper {
        max-width: $container-width;
        padding-bottom: 100px;
        padding-left: 0;
        padding-right: 0;
        margin: auto;
        overflow: hidden;

        .content, .form {
          float: left;
          width: 50%;
        }

        .content {
          padding-top: 70px;
          padding-left: $container-left-padding;
        }
      }
    }

    &.full-column {
      .main-content {
        .wrapper {
          .content, .form {
            padding-left: 0;
            width: 100%;
          }
        }
      }
    }
  }

}

@media (max-width: $mobile-breakpoint) {
  #div_id_number_of_rooms {
    label {
      display: block;
      padding-top: 14px;
      padding-bottom: 8px;
    }

    .required-field {
      margin-top: -2px;
    }
  }

  .form {
    .date-field {
      .ctrlHolder {
        .required-field {
          margin-top: -2px;
        }
      }
    }
  }
}

.app-miss-lilys {
  .content,
  .form {
    h1 {
      border-bottom: 0;
      padding-bottom: 10px;
      margin-bottom: 0;
      padding-bottom: 0;
      color: #2aaae3;
    }

    .heading {
      padding-bottom: 10px;
      color: #fede00;
      border-bottom: 2px solid #28bb5b;
    }
  }
}

.delivery-choices {
  list-style: none;

  overflow: hidden;
  text-align: center;

  li {
    display: inline-block;

    padding: 10px 0;
    text-align: center;

    img {
      display: block;
      height: 50px;
      max-width: 100%;
      width: auto;
    }

    &.white-bg {
      img {
        background-color: #fff;
      }
    }
  }
}

@media(max-width: 816px) {
  .app-miss-lilys .reservations .main-content {
    padding-top: 30px;

    .wrapper .content {
      padding-top: 10px;
    }
  }

  .restaurant-logos {
    padding-bottom: 20px;

    > li {
      padding-top: 25px;

      &:first-child {
        padding-top: 0;
      }

      margin-bottom: 10px;
    }
  }
}

@media(min-width: 815px) {
  .app-miss-lilys {
    .reservations .main-content {
      padding-top: 30px;

      .wrapper .content {
        padding-top: 10px;
      }

      .heading:first-child {
        margin-bottom: 40px;
      }
    }
  }
}
