.supporter-overlay {
  display: none;
  opacity: 0;
  z-index: 2000;

  transition: opacity 0.5s linear;

  overflow: scroll;

  &.enabled {
    @include stretch();
    opacity: 1;

    display: block;
    background-color: #000;
  }

  .supporter-content {
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    color: #000;
    max-width: 750px;

    h2 {
      font-size: 30px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #888;
    }

    strong {
      font-weight: bold;
    }

    ul {
      overflow: hidden;

      > li {
        margin: 10px 0;
        vertical-align: top;
      }
    }
  }
}

@media(min-width: $mobile-breakpoint + 1) {
  .supporter-overlay {
    .supporter-content {
      ul {
        li {
          padding-right: 20px;
          width: 32%;
          display: inline-block;
        }
      }
    }
  }
}

.show-supporters {
  overflow: hidden;

  header {
    background-color: #000;
    ul {
      display: none;
    }
  }
}
