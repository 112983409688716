//.show-nav {
//  overflow: hidden;
//}

nav {
  @include font-sans-serif(true);
  position: fixed;
  top: 0;
  left: - $menu-width;
  bottom: 0;
  transition: left ease-out 0.4s;
  width: $menu-width;
  z-index: 2500;
  background-image: url('../img/structure/bg-nav-menu.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: $menu-background-color;

  .nav-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    padding: 0 20px;
  }

  .cms-toolbar-expanded & {
    top: $menu-alt-top;
  }

  .show-nav & {
    left: 0;
  }

  ul {
    padding-top: 20px;
    text-align: center;

    .app-miss-lilys & {
      text-align: left;
    }

    margin: 0;

    > li {
      > ul {
        padding-top: 0;
        //font-size: 0.9em;

        > li {
          position: relative;
          padding-bottom: 7px;
          padding-left: 17px;

          &:before {
            position: absolute;
            left: 5px;
            top: 0;
            font-size: 1.5em;
            content: "-";
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &:last-child {
        padding-bottom: 0;
      }

      .subnav-title, a {
        color: $main-nav-color;
        text-decoration: none;
        font-size: 1.5em;
        letter-spacing: 0.03em;
      }

      .subnav-title {
        display: block;
        padding-bottom: 10px;
      }
      //color: $main-nav-color-active;
      //font-weight: bold;
      //}

      a:hover {
        color: $main-nav-color-hover;
      }

      padding-bottom: 10px;
    }

    > li.selected {
      > a {
        color: $main-nav-color-active;
      }
    }
  }

  .section {
    border-bottom: 1px solid $main-nav-section-divider-color;
    margin-bottom: 25px;
    padding-bottom: 25px;
    text-align: center;
    color: $main-nav-color;

    a {
      text-decoration: none;
      color: $main-nav-color;

      img {
        display: block;
        width: 160px;
        //				height: 70px;

        margin: 0 auto;
      }

      strong {
        display: block;
        color: #fff;
        font-weight: normal;
      }
    }

    label {
      display: block;
      //			color: #
      margin-bottom: 5px;
    }

    &.form-section {
      .buttonHolder, .ctrlHolder {
        //				padding: 0 20px;
      }

      .buttonHolder {
        padding-right: 15px;

        input {
          font-size: 14px;
          padding: 5px;
        }
      }

      .thank-you {
        padding: 49px 0;
        display: none;
      }

      &.form-success {
        form {
          display: none;
        }

        .thank-you {
          display: block;
        }
      }
    }
    .ctrlHolder {
      margin: 0;
      //			input {
      //				width: 100%;
      //				padding: 4px;
      //				font-size: 12px;
      //				background-color: #fff;
      //				border: 1px solid #fff;
      //			}
      //
      //			&.error {
      //				input {
      //					border-color: $field-input-error-border;
      //				}
      //			}
    }

    .buttonHolder {
      margin: 5px 0 0;
      input {
        float: right;
        border: 0;
      }
    }
  }

  ul.social-networks {
    padding: 0;
    height: $header-social-network-icons-box-size;
    position: relative;
    text-align: center;

    li {
      display: inline-block;
    }
  }

  .fixed-footer {
    padding-bottom: 120px;

    .footer-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .footer-content {
    color: $main-nav-color;
    text-align: center;
    //font-size: 0.85em;
    line-height: 1.2em;
    padding-bottom: 35px;

    p {
      padding-top: 10px;

      a {
        color: $main-nav-color;
        text-decoration: none;

        &:hover {
          color: $main-nav-color-hover;
        }
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }
}
