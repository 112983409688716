$menu-close-button-width: 20px;
$menu-close-button-space: 3px;
$menu-close-button-height: 2px;

@mixin site-logos( $alt: false ) {
  $version: "-white";
  @if ($alt) {
    $version: "";
  }

  a.hotel {
    background-image: url("../img/structure/logo-rockhouse" + $version + ".png");
  }

  a.misslilys {
    background-image: url("../img/structure/logo-misslilys" + $version + ".png");
  }

  a.foundation {
    background-image: url("../img/structure/logo-rockhousefoundation-header" + $version + ".png");
  }
}

.menu-and-logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  padding-left: 20px;
  padding-top: 15px;

  .cms-toolbar-expanded & {
    top: $menu-alt-top;
  }

  h1 {
    padding-left: 5px;
    float: left;

    a {
      transition: background-image $header-fade-easing $header-fade-speed;
      display: block;
      height: 31px;
      width: 138px;
      background-repeat: no-repeat;
      background-position: 0 0;
      text-indent: -9999px;
      overflow: hidden;
      background-size: contain;
    }

    .header-alt & {
      @include site-logos(true);

      //a.hotel {
      //  background-image: url(../img/structure/logo-rockhouse.png);
      //}
      //
      //a.misslilys {
      //  background-image: url(../img/structure/logo-misslilys.png);
      //}
      //
      //a.foundation {
      //  background-image: url(../img/structure/logo-rockhousefoundation-header.png);
      //}
    }

    &, .show-nav &, .show-reservations &, .show-supporters & {
      @include site-logos();
      //a.misslilys {
      //  background-image: url(../img/structure/logo-misslilys-white.png);
      //}
      //
      //a.hotel {
      //  background-image: url(../img/structure/logo-rockhouse-white.png);
      //}
      //
      //a.foundation {
      //  background-image: url(../img/structure/logo-rockhousefoundation-header-white.png);
      //}
    }
  }

  a#menu {
    position: relative;
    display: block;
    float: left;
    padding: 8px 0 10px;
    width: 30px;
    text-decoration: none;
  }

  .icon-bar {
    width: $menu-close-button-width;
    height: $menu-close-button-height;
    background-color: $menu-button-normal;
    display: block;
    transition: all $menu-open-easing $menu-open-speed;
    z-index: 1001;

    &:before, &:after {
      content: "";
      display: block;
      position: relative;
      z-index: 1001;
      transition: all $menu-open-easing $menu-open-speed;
      transform-origin: 15px;
      width: $menu-close-button-width;
      height: $menu-close-button-height;
      background-color: $menu-button-normal;
    }

    &:before {
      top: - ( $menu-close-button-height + $menu-close-button-space );
    }

    &:after {
      top: $menu-close-button-space;
    }

    .header-alt & {
      background-color: $menu-button-normal-alt;

      &:before, &:after {
        background-color: $menu-button-normal-alt;
      }
    }

    .show-reservations & {
      background-color: $menu-button-normal;

      &:before, &:after {
        background-color: $menu-button-normal;
      }
    }
  }

  label {
    @include font-sans-serif(true);
    transition: all $menu-open-easing $menu-open-speed;
    display: block;
    padding-top: 8px;
    cursor: pointer;
    color: $menu-button-normal;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    .header-alt & {
      color: $menu-button-normal-alt;
    }

    .show-nav & {
      opacity: 0;
      // color: $menu-button-active;
    }
  }
}

.show-nav .menu-and-logo, .show-reservations .menu-and-logo, .show-supporters .menu-and-logo {
  label {
    opacity: 0;
    color: $menu-button-active;
  }

  a#menu {
    .icon-bar {
      &:before {
        transform: rotate(-45deg);
        background-color: $menu-button-active;
        top: -5px;
      }

      &:after {
        transform: rotate(45deg);
        background-color: $menu-button-active;
        top: 0;
      }

      background-color: rgba(0, 0, 0, 0);
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .menu-and-logo {
    h1 {
      @include site-logos(true);

      .app-miss-lilys & a.misslilys {
        background-image: none;
      }
    }

    .icon-bar {
      background-color: $menu-button-normal-alt;

      &:before, &:after {
        background-color: $menu-button-normal-alt;
      }
    }

    label {
      color: $menu-button-normal-alt;

      .show-reservations & {
        color: $menu-button-active;
      }
    }
  }
}
