.main-content {
	transition: top 0.5s;
	top: 0;
}

.show-preloader {
	//overflow: hidden;

	.preloader {
    pointer-events:none;

		display: block;

		@include stretch();

		z-index: 9999;
		opacity: 1;

		&.fade-out {
			.background {
				opacity: 0;
			}
		}

		//		background-color: #fff;
		.background {
			display: none;
			@include stretch($position: absolute, $cms-top-fix: false);

			background-color: #222;
			transition: opacity 0.5s;
		}

		h1 {
			position: absolute;
			top: 50%;
			width: 100%;

			margin-top: -85px; // 15
			font-size: 100px;
			text-align: center;

			color: #fff;

			transition: margin-top 0.5s, opacity 0.5s;
		}

		.progress {
			width: 250px;
			height: 3px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: 12px;
			margin-left: -125px;

			.bar {
				position: absolute;

				top: 0;
				left: 0;
				bottom: 0;
				width: 0;
				background-color: #fff;

				transition: 0.5s linear width;
			}
		}

		&.completed {
			.progress {
				.bar {
					transition: 0.25s linear width;
					left: auto;
					right: 0;
				}
			}
		}

		&.animate-text {
			h1 {
				opacity: 0;
				margin-top: -150px;
			}
		}
	}
}

.preloader {
	display: none;
}
