@mixin flippity-flop($color) {
  a.cube {
    * {
      font-size: 14px;
      line-height: 15px;
    }

    .flippety, .flop {
      background-color: $color;
    }
  }
}

@mixin header-alt() {
  background-color: #fff;

  a.close-button {
    span, span:before, span:after {
      background-color: $menu-button-hover;
    }
  }
}

header {
  position: fixed;
  z-index: 2500;
  top: 0;
  left: 0;
  right: 0;
  height: 58px;

  .cms-toolbar-expanded & {
    top: $header-alt-top;
  }

  padding: 9px 20px;
  transition: background-color $header-fade-easing $header-fade-speed;

  ul {
    float: right;

    li {
      float: left;
    }

    li.book-now {
      padding-left: 10px;

      a {
        @include font-sans-serif(true);
        transition: background-color $header-fade-easing $header-fade-speed;
        display: block;
        padding: $header-book-now-padding;
        font-size: $header-book-now-font-size;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        color: $header-book-now-color;
        background-color: $header-book-now-background-color;
      }

      a:hover {
        background-color: $header-book-now-background-color-hover;
      }
    }

    /* Container box to set the sides relative to */
  }

  .header-alt & {
    @include header-alt();
  }
}

ul.social-networks {
  text-align: left;

  li {
    a.cube {
      display: block;
      width: $header-social-network-icons-box-size;
      height: $header-social-network-icons-box-size;
      text-align: center;
      margin: 0 auto;
      color: $header-social-network-icons;
      transition: transform .33s; /* Animate the transform properties */
      transform-style: preserve-3d; /* <-NB */

      /* Rotate the cube */
      &:hover {
        transform: rotateX(89deg); /* Text bleed at 90 */
      }

      .flippety, .flop {
        height: $header-social-network-icons-box-size;
      }

      /* Position the faces */
      .flippety {
        transform: translateZ($header-social-network-icons-box-size / 2);
      }

      .flop {
        transform: rotateX(-90deg) translateZ(-$header-social-network-icons-box-size / 2);
      }

      span {
        display: block;
        padding: 12px;
      }
    }

  }

  li.label-follow-us {
    @include font-sans-serif(true);
    color: #999;
    padding: 13px;
    font-size: 0.85em;
  }

  li.facebook {
    @include flippity-flop($facebook-color);
  }

  li.instagram {
    @include flippity-flop($instragram-color);
  }

  li.twitter {
    @include flippity-flop($twitter-color);
  }

  li.tumblr {
    @include flippity-flop($tumblr-color);
  }

  li.flickr {
    @include flippity-flop($flickr-color);
  }
}

@media (max-width: $mobile-breakpoint) {
  body {
    header {
      @include header-alt();
    }
  }
}
